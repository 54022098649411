<div class="max-w-screen-sm mx-auto py-8 px-4 md:px-8 min-h-screen">
  <form class="ea-form">
  <fieldset>
  <div class="ea-form">
  <div class="flex flex-col gap-4">
    <div
    class="p-4 lg:p-8 bg-theme-surface text-theme-on-surface border border-gray-300 rounded"
    style="text-align: center;"
  >
  <h1 class="text-2xl font-bold font-title mb-4">Thank You!</h1>
  <p class="text-1xl font-bold font-title mb-4">Account successfully created. 
    Please verify your account via the email we just sent. This ensures security, allows order updates, and protects against identity risks.</p>
  </div>
</div>
  </div>
</fieldset>
</form>
</div>